import * as React from "react";

function UxUiDevelopmentIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} viewBox="0 0 35 35" {...props}>
      <circle cx="17.5" cy="17.5" r="15" fill="#34a853" />
      <rect x="10" y="10" width="15" height="15" fill="#ffffff" />
      <path d="M17.5 10 L17.5 25 M10 17.5 L25 17.5" stroke="#34a853" strokeWidth="2" />
    </svg>
  );
}

export default UxUiDevelopmentIcon;
