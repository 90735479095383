import React from 'react'
import styled from "styled-components";
import './ValueSection.css';
import valueimg from "../../assets/img/value-img.png";

const ValueSection = () => {
  return (
    <div className='section-container'>
      <h1 className='Value-Section-title'>Our Values</h1>
      <div className='value-container'>
        <ul className='feature-list'>
          <li className='feature-item'>
            <span className='feature-title'>Client-Focused</span>: We believe in a collaborative approach that prioritizes our clients’ needs and goals.
          </li>
          <li className='feature-item'>
            <span className='feature-title'>Innovation-Driven</span>: Constantly evolving, we stay ahead of industry trends to bring the latest innovations to our clients.
          </li>
          <li className='feature-item'>
            <span className='feature-title'>Excellence and Integrity</span>: Quality and honesty are at the core of everything we do, from development to customer support.
          </li>
        </ul>
        <ImageWrapper className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex valueimg">
        <img src={valueimg} alt="office" className="responsiveImg" />
      </ImageWrapper>
      </div>
    
      <div>
      </div>
    </div>
  )
}

export default ValueSection


const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  @media (max-width: 860px) {
    justify-content: center;
  }
  img.responsiveImg {
    width: 90%;
    height: auto;
    max-width: 700px;
    border-radius: 6px;
    @media (max-width: 768px) {
      max-width: 100%;
      height: auto;
    }
    @media (max-width: 480px) {
      max-width: 90%;
    }
    @media (max-width: 320px) {
      max-width: 100%;
    }
  }
`;