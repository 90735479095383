import React from "react";
import styled from "styled-components";

// Components
import FullButton from "../Buttons/FullButton";

import AboutUsImage from "../../assets/img/home-aboutus-img.png";
import { Link } from "react-router-dom";

export default function AboutUs() {
  return (
    <Wrapper id="services">
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="lightBg">
          <div className="container">
            <div
              className="row"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <Form>
                  <h4 className="font15 semiBold">
                    About SmoothSync Innovations{" "}
                  </h4>
                  <h2 className="font40 extraBold">A Study of Creativity</h2>
                  <p className="font12">
                    At SmoothSync Innovations, we’re passionate about helping
                    businesses grow and succeed in the digital world. Our team
                    of skilled developers, designers, and strategists work
                    together to create tailored, impactful solutions for every
                    client. With a focus on quality, innovation, and customer
                    satisfaction, we bring your ideas to life with seamless,
                    future-ready technology.
                  </p>
                  <p className="font12">
                    Whether it’s building a custom website, developing a unique
                    app, or designing a brand experience, we’re here to guide
                    you every step of the way.
                  </p>
                  {/* <ButtonsRow
                    className="flexNullCenter"
                    style={{ margin: "30px 0" }}
                  >
                    <div style={{ width: "190px" }}>
                      <Link to="/contact-us" style={{ color: "#000000" }}>
                        <FullButton title="Contact Us" border />
                      </Link>
                    </div>
                  </ButtonsRow> */}
                  <BtnWrapper>
                    <Link to="/contact-us" style={{ color: "#000000" }}>
                      <FullButton title="Contact Us" />
                    </Link>
                  </BtnWrapper>
                </Form>
              </div>
              <ImageWrapper className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
                <img
                  src={AboutUsImage}
                  alt="office"
                  className="responsiveImg"
                />
              </ImageWrapper>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;

const Form = styled.form`
  padding: 40px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
`;

const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  @media (max-width: 860px) {
    justify-content: center;
  }
  img.responsiveImg {
    width: 100%;
    height: auto;
    max-width: 700px;
    border-radius: 6px;
    @media (max-width: 768px) {
      max-width: 100%;
      height: auto;
    }
    @media (max-width: 480px) {
      max-width: 90%;
    }
    @media (max-width: 320px) {
      max-width: 100%;
    }
  }
`;


