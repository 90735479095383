// import * as React from "react";

// function SocialMedia(props) {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width={39.581}
//       height={39.58}
//       viewBox="0 0 39.581 39.58"
//       {...props}
//     >
//       <g>
//         <circle cx="19.79" cy="19.79" r="5" fill="#7620ff" />
//         <circle cx="5.5" cy="5.5" r="4" fill="#0b093b" />
//         <circle cx="34.08" cy="5.5" r="4" fill="#0b093b" />
//         <circle cx="5.5" cy="34.08" r="4" fill="#0b093b" />
//         <circle cx="34.08" cy="34.08" r="4" fill="#0b093b" />
//         <line
//           x1="19.79"
//           y1="19.79"
//           x2="5.5"
//           y2="5.5"
//           stroke="#7620ff"
//           strokeWidth="2"
//         />
//         <line
//           x1="19.79"
//           y1="19.79"
//           x2="34.08"
//           y2="5.5"
//           stroke="#7620ff"
//           strokeWidth="2"
//         />
//         <line
//           x1="19.79"
//           y1="19.79"
//           x2="5.5"
//           y2="34.08"
//           stroke="#7620ff"
//           strokeWidth="2"
//         />
//         <line
//           x1="19.79"
//           y1="19.79"
//           x2="34.08"
//           y2="34.08"
//           stroke="#7620ff"
//           strokeWidth="2"
//         />
//       </g>
//     </svg>
//   );
// }

// export default SocialMedia;

import * as React from "react";

function SocialMedia(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={39.581}
      height={39.58}
      viewBox="0 0 39.581 39.58"
      {...props}
    >
      <circle cx="19.79" cy="19.79" r="4" fill="#1da1f2" />
      <circle cx="10" cy="10" r="3.5" fill="#e1306c" />
      <circle cx="30" cy="10" r="3.5" fill="#1877f2" />
      <circle cx="10" cy="30" r="3.5" fill="#ff5700" />
      <circle cx="30" cy="30" r="3.5" fill="#25d366" />
      <line
        x1="19.79"
        y1="19.79"
        x2="10"
        y2="10"
        stroke="#888"
        strokeWidth="1.5"
      />
      <line
        x1="19.79"
        y1="19.79"
        x2="30"
        y2="10"
        stroke="#888"
        strokeWidth="1.5"
      />
      <line
        x1="19.79"
        y1="19.79"
        x2="10"
        y2="30"
        stroke="#888"
        strokeWidth="1.5"
      />
      <line
        x1="19.79"
        y1="19.79"
        x2="30"
        y2="30"
        stroke="#888"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default SocialMedia;
