import * as React from "react";

function SocialMediaMarketingIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} viewBox="0 0 35 35" {...props}>
      <circle cx="17.5" cy="17.5" r="15" fill="#ea4335" />
      <path
        d="M17.5 5 L12 15 L23 15 L17.5 25 Z"
        fill="#ffffff"
      />
      <circle cx="17.5" cy="17.5" r="3" fill="#ea4335" />
    </svg>
  );
}

export default SocialMediaMarketingIcon;
