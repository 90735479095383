import * as React from "react";

function AppDevelopmentIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} viewBox="0 0 35 35" {...props}>
    <rect x="5" y="5" width="25" height="25" fill="#4CAF50" />
    <rect x="8" y="8" width="19" height="19" fill="#ffffff" />
    <line x1="8" y1="8" x2="27" y2="8" stroke="#4CAF50" strokeWidth="2" />
    <line x1="8" y1="27" x2="27" y2="27" stroke="#4CAF50" strokeWidth="2" />
    <line x1="8" y1="8" x2="8" y2="27" stroke="#4CAF50" strokeWidth="2" />
    <line x1="27" y1="8" x2="27" y2="27" stroke="#4CAF50" strokeWidth="2" />
  </svg>
  
  );
}

export default AppDevelopmentIcon;
