import React from 'react'
import Careers from '../components/Career/Careers'
import CareerList from '../components/Career/CareerList'
import Internships from '../components/Career/Internships'
import HowToApply from '../components/Career/HowToApply'
import LifeAtSmoothSync from '../components/Career/LifeAtSmoothSync'
import Footer from '../components/Sections/Footer'

const career = () => {
  return (
   <>
   <Careers/>
   <CareerList/>
   <Internships/>
   <LifeAtSmoothSync/>
   <HowToApply/>
   <Footer/>
   </>
  )
}

export default career
