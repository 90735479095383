import React from 'react';
import './Header.css';
import aboutimg from "../../assets/img/about-img1.png";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import FullButton from "../Buttons/FullButton";

const Header = () => {
  return (
    <div className="header-container">
      <div className="header-content">
        <h1 className="header-title">Welcome to <span style={{color:"blue"}}>SmoothSync Innovations</span> </h1>
        <p className="header-description">
          At SmoothSync Innovations, we believe in transforming ideas into dynamic digital solutions
          that empower businesses. Specializing in web and app development, UX/UI design, custom
          ERP systems, and social media content, we are committed to creating impactful solutions
          tailored to meet each client’s unique needs. We prioritize quality, innovation, and building
          lasting partnerships to ensure your business stands out in the digital world.
        </p>
        {/* <Link to="/contact-us" className="cta-button">
            Contact Us
          </Link> */}
            <BtnWrapper>
          <Link to="/contact-us" style={{ color: "#000000" }}>
            <FullButton title="Contact Us" />
            </Link>
          </BtnWrapper>
      </div>
      <ImageWrapper className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
        <img src={aboutimg} alt="office" className="responsiveImg" />
      </ImageWrapper>
    </div>
  );
}

export default Header;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  @media (max-width: 860px) {
    justify-content: center;
  }
  img.responsiveImg {
    width: 100%;
    height: auto;
    max-width: 700px;
    border-radius: 6px;
    @media (max-width: 768px) {
      max-width: 80%;
      height: auto;
    }
    @media (max-width: 480px) {
      max-width: 100%;
    }
    @media (max-width: 320px) {
      max-width: 100%;
    }
  }
`;

const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
