import React from 'react';
import './HowToApply.css';

const HowToApply = () => {
    return (
        <div className="apply-section">
            <div className="container apply-container">
                <h2 className="apply-title">How to Apply</h2>
                <p className="apply-text">
                    Interested candidates should send their resume and a brief cover letter to{' '}
                    <a  href="mailto:info@smoothsyncinnovations.com" className="apply-email">info@smoothsyncinnovations.com</a>.
                    Please mention the internship title in the subject line.
                </p>
            </div>
        </div>
    );
};

export default HowToApply;
