import React from 'react';
import Header from '../components/About/Header';
import Mission from '../components/About/Mission';
import ValueSection from '../components/About/ValueSection';
import { Profilegrid } from '../components/About/ProfileCard';
import Footer from "../components/Sections/Footer"
import WhyChooseUs from '../components/About/whyChooseus';


const About = () => {
  return (
      <>
        <Header/>
        <Mission/>
        <ValueSection/>
        <Profilegrid/>
        <WhyChooseUs/>
        <Footer/>
        </>
  );
}

export default About;

