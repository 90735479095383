import React from "react";
// Sections
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Footer from "../components/Sections/Footer";
import AboutUs from "../components/Sections/About";
import WhyChooseUs from "../components/About/whyChooseus";

export default function Home() {
  return (
    <>
      <Header />
      <Services />
      <AboutUs />
      <WhyChooseUs />
      <Footer />
    </>
  );
}
