// import React from "react";
// import styled from "styled-components";
// // Components
// import ServiceBox from "../Elements/ServiceBox";


// export default function Services() {
//   return (
//     <Wrapper id="services">
//       <div className="whiteBg" style={{ padding: "60px 0" }}>
//         <div className="container">
//           <HeaderInfo>
//             <h1 className="font40 extraBold">Our Services</h1>
//           </HeaderInfo>
//           <ServiceBoxRow className="flex">
//             <ServiceBoxWrapper>
//               <ServiceBox
//                 icon="roller"
//                 title="Web Development"
//                 subtitle="Creating responsive, high-performance websites tailored to your business needs."
//               />
//             </ServiceBoxWrapper>
//             <ServiceBoxWrapper>
//               <ServiceBox
//                 icon="monitor"
//                 title="App Development"
//                 subtitle="Building mobile and web applications that deliver seamless user experiences across all devices."
//               />
//             </ServiceBoxWrapper>
//             <ServiceBoxWrapper>
//               <ServiceBox
//                 icon="browser"
//                 title="UX/UI Design"
//                 subtitle="Crafting intuitive, user-centered designs that enhance engagement and usability."
//               />
//             </ServiceBoxWrapper>
//             <ServiceBoxWrapper>
//               <ServiceBox
//                 icon="printer"
//                 title="Custom ERP Solutions"
//                 subtitle="Developing scalable ERP systems to streamline your operations and improve efficiency."
//               />
//             </ServiceBoxWrapper>
//             <ServiceBoxWrapper>
//               <ServiceBox
//                 icon="printer"
//                 title="Social Media Content"
//                 subtitle="Designing impactful content to boost your brand's online presence and connect with your audience."
//               />
//             </ServiceBoxWrapper>
//           </ServiceBoxRow>
//         </div>
//       </div>
//     </Wrapper>
//   );
// }

// const Wrapper = styled.section`
//   width: 100%;
// `;
// const ServiceBoxRow = styled.div`
//   @media (max-width: 860px) {
//     flex-direction: column;
//   }
// `;
// const ServiceBoxWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   background-color: #f8f8f8;
//   padding: 20px;
//   margin: 20px;
//   border-radius: 10px;
//   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
//   text-align: center;
//   transition: transform 0.3s ease, box-shadow 0.3s ease;
//   width: 20%;
//   min-height: 250px;

//   @media (max-width: 860px) {
//     width: 100%;
//     padding: 40px 0;
//   }

//   &:hover {
//     transform: translateY(-5px);
//     box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.15);
//   }
// `;

// const HeaderInfo = styled.div`
//   text-align: center;
// `;


import React from "react";
import styled from "styled-components";
// Components
import ServiceBox from "../Elements/ServiceBox";

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Services</h1>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="Web Development"
                subtitle="Creating responsive, high-performance websites tailored to your business needs."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title="App Development"
                subtitle="Building mobile and web applications that deliver seamless user experiences across all devices."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="roller"
                title="UX/UI Design"
                subtitle="Crafting intuitive, user-centered designs that enhance engagement and usability."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="printer"
                title="Custom ERP Solutions"
                subtitle="Developing scalable ERP systems to streamline your operations and improve efficiency."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="socialmedia"
                title="Social Media Content"
                subtitle="Designing impactful content to boost your brand's online presence and connect with your audience."
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const ServiceBoxRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: nowrap;  // All cards will stay in a single row by default

  @media (max-width: 1024px) {
    justify-content: center;  // Center cards when width is smaller
  }

  @media (max-width: 860px) {
    flex-direction: column;
    align-items: center;  // Stack cards vertically on mobile devices
  }
`;

const ServiceBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  flex: 1;  // Ensure all cards take up equal width

  @media (max-width: 1024px) {
    width: 45%;  // 2 cards per row on tablets
    margin: 15px 0;
  }

  @media (max-width: 768px) {
    width: 90%;  // 1 card per row on mobile
    margin: 20px 0;
    padding: 30px 20px;  // More padding on smaller screens
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.15);
  }
`;

const HeaderInfo = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;
