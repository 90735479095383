import React, { useEffect, useRef } from 'react'
import './Mission.css'
import styled from "styled-components";
import missionimg from "../../assets/img/Mission-img.png";

const Mission = () => {
  const missionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
          }
        });
      },
      { threshold: 0.3 }
    );

    if (missionRef.current) {
      const elements = missionRef.current.querySelectorAll('.fadeIn');
      elements.forEach((el) => observer.observe(el));
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className='section'>
      <div className='mission-container' ref={missionRef}>
        <ImageWrapper className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex image-wrapper">
          <img src={missionimg} alt="office" className="responsiveImg" />
        </ImageWrapper>
        <div className='mission'>
          <div className='title fadeIn'>
            <h1>Our Mission</h1>
          </div>
          <div className='content fadeIn'>
            <p>To deliver seamless, technology-driven solutions that foster growth and drive success for
              every client. Our focus is on quality and client satisfaction, ensuring each project aligns with
              your vision and goals.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mission

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  @media (max-width: 860px) {
    justify-content: center;
  }
  img.responsiveImg {
    width: 100%;
    height: auto;
    max-width: 700px;
    border-radius: 6px;
    @media (max-width: 768px) {
      max-width: 60%;
      height: auto;
    }
    @media (max-width: 480px) {
      max-width: 90%;
    }
    @media (max-width: 320px) {
      max-width: 100%;
    }
  }
`;


