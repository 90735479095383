import React from "react";
import styled from "styled-components";
import DetailsBar from "../components/Sections/DetailsBar";
import InputSide from "../components/Sections/InputSide";
import Footer from "../components/Sections/Footer";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 100px;
  align-items: center;
  background-color: whitesmoke;
  padding-bottom: 50px;
`;

const PageHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

const FormContainer = styled.div`
  width: 65%;
  min-width: 600px;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  height: auto;
  grid-gap: 10px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 100%;
    max-width: 500px;
    min-width: 0px;
    grid-gap: 0px;
  }
`;

const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  text-align: center;
`;

const ContactUs = () => {
  return (
    <>
      <PageWrapper>
        <PageHeadingWrapper>
          <HeaderInfo>
            <h1 className="font40 extraBold">Contact Us</h1>
            <p style={{ fontSize: "1rem" }}>
              Have a question or ready to start your next project? We're here to
              help! Reach out to SmoothSync Innovations and let's discuss how we
              can bring your vision to life.
            </p>
          </HeaderInfo>
        </PageHeadingWrapper>
        <FormContainer>
          <DetailsBar />
          <InputSide />
        </FormContainer>
      </PageWrapper>
      <Footer />
    </>
  );
};

export default ContactUs;
