import React from 'react';
import './whyChooseus.css';
import styled from "styled-components";
import whychoose from "../../assets/img/whychoose.png";

const WhyChooseUs = () => {
  return (
    <section className="why-choose-us">
      <h1>Why Choose Us?</h1>

      <div className="why-choose-us-content">
        <div className="left-side ">
          <ImageWrapper className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex whyimage">
            <img src={whychoose} alt="office" className="responsiveImg" />
          </ImageWrapper>
        </div>

        <div className="right-side">
          <div className="grid-container">
            <div className="grid-item">
              <h3>Unmatched Quality</h3>
              <p>We deliver top-notch solutions, ensuring every project meets the highest standards of excellence.</p>
            </div>
            <div className="grid-item">
              <h3>Timely Delivery</h3>
              <p>We respect your time, providing reliable, on-time project completion without compromising quality.</p>
            </div>
            <div className="grid-item">
              <h3>Expert Team</h3>
              <p>Our experienced professionals bring deep expertise to each project, ensuring the best outcomes.</p>
            </div>
            <div className="grid-item">
              <h3>Cost-Effective Solutions</h3>
              <p>High-quality work at competitive prices, giving you the best value for your investment.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;


const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  @media (max-width: 860px) {
    justify-content: center;
  }
  img.responsiveImg {
    width: 90%;
    height: auto;
    max-width: 700px;
    border-radius: 6px;
    @media (max-width: 768px) {
      max-width: 80%;
      height: auto;
    }
    @media (max-width: 480px) {
      max-width: 90%;
    }
    @media (max-width: 320px) {
      max-width: 100%;
    }
  }
`;
