import React from 'react';
import './LifeAtSmoothSync.css';
import aboutimg from "../../assets/img/LifeAt.png";
import styled from "styled-components";

const LifeAtSmoothSync = () => {
  return (
    <div className="life-container">
      <div className="life-content">
        <div className="life-text">
          <h2>Life at SmoothSync Innovations</h2>
          <p>
            At SmoothSync Innovations, interns are part of our team from day one. You’ll be involved in
            meaningful projects, gain valuable skills, and experience a collaborative work environment
            designed to help you succeed.
          </p>
        </div>
        <ImageWrapper className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
        <img src={aboutimg} alt="office" className="responsiveImg" />
      </ImageWrapper>
      </div>
    </div>
  );
};

export default LifeAtSmoothSync;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  @media (max-width: 860px) {
    justify-content: center;
  }
  img.responsiveImg {
    width: 100%;
    height: auto;
    max-width: 700px;
    border-radius: 6px;
    @media (max-width: 768px) {
      max-width: 80%;
      height: auto;
    }
    @media (max-width: 480px) {
      max-width: 100%;
    }
    @media (max-width: 320px) {
      max-width: 100%;
    }
  }
`;
