import React from "react";
// Sections
import Footer from "../components/Sections/Footer"
import ServicesBar from "../components/Sections/ServicesBar";
import WhyChooseUs from "../components/About/whyChooseus";

export default function Services() {
  return (
    <>
      <ServicesBar />
      <WhyChooseUs/>
      <Footer />
    </>
  );
}


