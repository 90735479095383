import React from 'react';
import './CareerList.css';

const CareerList = () => {
  return (
    <div className="container career-list-container">
      <h2>Why Intern with Us?</h2>
      <ul className="career-list">
        <li><span className="highlight-text">Practical Experience:</span> Work directly on client and in-house projects.</li>
        <li><span className="highlight-text">Professional Mentorship:</span> Receive guidance from industry experts.</li>
        <li><span className="highlight-text">Growth-Oriented:</span> Build foundational skills for a successful career.</li>
        <li><span className="highlight-text">Collaborative Culture:</span> Be part of an innovative and supportive team.</li>
      </ul>
    </div>
  );
};

export default CareerList;
