import * as React from "react";

function CustomErpSolutionsIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} viewBox="0 0 35 35" {...props}>
      <rect x="2" y="2" width="31" height="31" fill="#0f9d58" />
      <rect x="7" y="7" width="21" height="21" fill="#ffffff" />
      <path d="M10 10 L25 25 M25 10 L10 25" stroke="#0f9d58" strokeWidth="2" />
    </svg>
  );
}

export default CustomErpSolutionsIcon;
