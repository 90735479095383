import React from 'react';
import './Internships.css';

const Internships = () => {
  const internships = [
    {
      title: "Web Development Intern",
      location: "on-site",
      duration: "3-6 months",
      responsibilities:
        "Assist with front-end and back-end development tasks, collaborate with our team on website projects, and gain experience in coding, debugging, and optimization.",
      requirements:
        "Basic knowledge of HTML, CSS, JavaScript, and an interest in learning backend technologies."
    },
    {
      title: "UI/UX Development Intern",
      location: "on-site",
      duration: "3-6 months",
      responsibilities:
        "Work alongside our design team to create intuitive, user-friendly interfaces, assist in wireframing and prototyping, and gain insights into the full design process.",
      requirements:
        "Familiarity with design tools such as Figma, Adobe XD, or Sketch, and an interest in user experience and interaction design."
    },
    {
      title: "Data Analysis Intern",
      location: "on-site",
      duration: "3-6 months",
      responsibilities:
        "Support our data team in collecting, analyzing, and interpreting data to drive strategic decisions, learn how to create reports and visualizations, and assist in data cleaning and preparation.",
      requirements:
        "Basic knowledge of data analysis tools such as Excel, Google Sheets, or SQL; experience with Python or R is a plus."
    }
  ];

  return (
    <div className="container internships-container">
      {internships.map((internship, index) => (
        <div key={index} className="internship-card">
          <h3 className="internship-title">{internship.title}</h3>
          <p><span className='internship-text'>Location:</span> {internship.location}</p>
          <p><span className='internship-text'>Duration:</span> {internship.duration}</p>
          <p><span className='internship-text'>Responsibilities:</span> {internship.responsibilities}</p>
          <p><span className='internship-text'>Requirements:</span> {internship.requirements}</p>
        </div>
      ))}
    </div>
  );
};

export default Internships;
