import React from 'react';
import downloadImage from '../../assets/img/meet.jpg';
import downloadImage1 from '../../assets/img/Jignesh.jpg';
import downloadImage2 from '../../assets/img/sagar.jpeg';
import './ProfileCard.css';

// ProfileCard component
const ProfileCard = ({ name, description, quote, imageUrl }) => {
    return (
        <div className="profile-card">
            <div className="profile-image-wrapper">
                <img src={imageUrl} alt={`${name}'s profile`} className="profile-image" />
            </div>
            <div className="profile-content">
                <h3 className="profile-name">{name}</h3>
                <p className="profile-description">{description}</p>
                <blockquote className="profile-quote">{quote}</blockquote>
            </div>
        </div>
    );
};

// Profilegrid component
const Profilegrid = () => {
    const profiles = [
        {
            name: "Meet Gajjar – Founder & CEO",
            description:
                "Empowering growth through innovation and collaboration in every digital journey. As the CEO and Founder, I am dedicated to leading a company that creates impactful and reliable digital solutions.With a focus on innovation and quality, I work alongside a talented team to turn our clients' visions into reality and drive success through technology.",
            quote:
            "“My goal is to build solutions that don’t just meet today’s needs but prepare clients for tomorrow’s opportunities.”",
            imageUrl: downloadImage,
        },
        {
            name: "Jignesh Talpada– Founder & CTO ",
            description:
                "As the driving force behind our technical innovations, Jignesh brings extensive experience in web development and system architecture. His expertise ensures our projects are technically sound, scalable, and future-proof.",
            quote:
                "“I’m passionate about creating technology that simplifies processes, increases efficiency, and enhances user experience.”",
            imageUrl: downloadImage1 // Replace with actual image URL
        },
        {
            name: "Sagar Padhiyar –  Founder & Managing Director ",
            description:
                "Sagar Padhiyar leads our social media and marketing efforts, driving brand growth and engagement. With a focus on strategic campaigns, he ensures our clients connect meaningfully with their audiences and achieve lasting visibility.",
            quote:
                "“Marketing should inspire, connect, and drive growth.”",
            imageUrl: downloadImage2
        },
    ];

    return (
        <div className="profile-grid">
            {profiles.map((profile, index) => (
                <ProfileCard
                    key={index}
                    name={profile.name}
                    description={profile.description}
                    quote={profile.quote}
                    imageUrl={profile.imageUrl}
                />
            ))}
        </div>
    );
};

export { ProfileCard, Profilegrid };


