import React from 'react';
import './Career.css';
import aboutimg from "../../assets/img/Career.png";
import styled from "styled-components";

const Careers = () => {
  return (
    <div className="container careers-container">
      <div className="careers-content">
        <h1>Careers at <span style={{color:"blue"}}>SmoothSync Innovations</span></h1>
        <p>
          Looking to start your career in tech? At SmoothSync Innovations, we offer
          internship programs that give you hands-on experience, mentorship, and the
          opportunity to work on real-world projects. Join us to learn, grow, and make an
          impact!
        </p>
      </div>
      <ImageWrapper className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
        <img src={aboutimg} alt="office" className="responsiveImg" />
      </ImageWrapper>
    </div>
  );
};

export default Careers;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  @media (max-width: 860px) {
    justify-content: center;
  }
  img.responsiveImg {
    width: 100%;
    height: auto;
    max-width: 700px;
    border-radius: 6px;
    @media (max-width: 768px) {
      max-width: 80%;
      height: auto;
    }
    @media (max-width: 480px) {
      max-width: 100%;
    }
    @media (max-width: 320px) {
      max-width: 100%;
    }
  }
`;
