import * as React from "react";

function WebDevelopmentIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} viewBox="0 0 35 35" {...props}>
      <path
        d="M4 4h27v27H4z"
        fill="#4285f4"
      />
      <path
        d="M10 10h15v15H10z"
        fill="#ffffff"
      />
      <path
        d="M13 13h9v9h-9z"
        fill="#4285f4"
      />
    </svg>
  );
}

export default WebDevelopmentIcon;
