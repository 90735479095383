import React from "react";
import styled from "styled-components";
// Components
import WebDevelopmentIcon from "../../assets/svg/Services/WebDevelopment";
import AppDevelopmentIcon from "../../assets/svg/Services/AppDevelopment";
import UxUiDevelopmentIcon from "../../assets/svg/Services/UxUiDevelopment";
import CustomErpSolutionsIcon from "../../assets/svg/Services/CustomErpSolutions";
import SocialMediaMarketingIcon from "../../assets/svg/Services/SocialMediaMarketing";

const servicesArray = [
    {
      title: "Web Development",
      description:
        "We build high-quality, responsive websites that reflect your brand and engage users. From landing pages to complex web applications, our web development services ensure a smooth, reliable experience across all devices.",
      icon: <WebDevelopmentIcon />,
    },
    {
      title: "App Development",
      description:
        "Our team creates user-friendly mobile and web applications that offer seamless performance. Whether it’s iOS, Android, or cross-platform, we bring your ideas to life with high-performance, engaging apps.",
      icon: <AppDevelopmentIcon />,
    },
    {
      title: "UX/UI Development",
      description:
        "We design intuitive and visually compelling interfaces that make navigating your digital platforms easy and enjoyable. Our UX/UI services enhance user engagement, leading to better experiences and improved customer loyalty.",
      icon: <UxUiDevelopmentIcon />,
    },
    {
      title: "Custom ERP Solutions",
      description:
        "Streamline your business operations with our custom ERP systems. Designed to meet your specific needs, our ERP solutions improve efficiency, reduce costs, and simplify workflows, empowering you to manage your business with ease.",
      icon: <CustomErpSolutionsIcon />,
    },
    {
      title: "Social Media & Content Marketing",
      description:
        "Build your brand's online presence and connect with your audience through impactful social media content. Our team creates tailored strategies and engaging content that resonates with your target audience, helping you stand out in a crowded market.",
      icon: <SocialMediaMarketingIcon />,
    },
  ];

const AppDevelopmentCard = ({ data }) => {
  return (
    <div className="card-container">
      <div className="card-icon">{data.icon}</div>
      <h3 className="card-title">{data.title}</h3>
      <p className="card-description">{data.description}</p>
    </div>
  );
};

export default function ServicesBar() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Services</h1>
            <p style={{fontSize:'1rem'}}>
              At SmoothSync Innovations, we offer a range of solutions tailored
              to help your business thrive in the digital landscape. Here’s how
              we can support your goals:
            </p>
          </HeaderInfo>
          <GridContainer>
            {servicesArray.map((item, index) => (
              <AppDevelopmentCard key={index} data={item} />
            ))}
          </GridContainer>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 150px;
`;

const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  margin-bottom: 30px;
  justify-content: center;
  align-items: stretch;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 30px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    margin-bottom: 30px;
  }
`;

